import React from "react"

import Layout from "../../components/layout"
import Nginx from "../../components/screens/Skill/Nginx"
import { Helmet } from "react-helmet"

const NginxPage = () => (
  <Layout pageInfo={{ pageName: "Nginx" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Nginx</title>
        <meta name="description" content="Nginx" />
    </Helmet>
    <Nginx />
  </Layout>
)

export default NginxPage
