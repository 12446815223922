import React, { Component } from "react"
import { Image } from "react-bootstrap"
import { OutboundLink } from 'gatsby-plugin-gtag'

class Nginx extends Component {
  render() {
    return (
      <div className="wrapper-skill">
        <Image
          alt="900x500"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
          data-src={require("../../../../images/skill/nginx.jpg")}
          className="bannerSkill lazyload"
        />
        <div className="tab-skill">
          <OutboundLink to="/capabilities/php-mysql" activeClassName="active">Php/mysql</OutboundLink>
          <OutboundLink to="/capabilities/jquery-ajax" activeClassName="active">Jquery/Ajax</OutboundLink>
          <OutboundLink to="/capabilities/magento" activeClassName="active">Magento</OutboundLink>
          <OutboundLink to="/capabilities/joomla" activeClassName="active">Joomla</OutboundLink>
          <OutboundLink to="/capabilities/wordpress" activeClassName="active">WordPress</OutboundLink>
          <OutboundLink to="/capabilities/html-css" activeClassName="active">Css3/html5</OutboundLink>
          <OutboundLink to="/capabilities/pts-illus" activeClassName="active">Phptoshop/Illustrator</OutboundLink>
          <OutboundLink to="/capabilities/nginx" activeClassName="active">Apache/Nginx</OutboundLink>
          <OutboundLink to="/capabilities/varnish" activeClassName="active">Varnish</OutboundLink>
        </div>
        <div className="content-skill">
          <h1 className="title-page-skill">Apache/ Nginx</h1>
        </div>
      </div>
    )
  }
}

export default Nginx;
